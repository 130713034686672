@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply antialiased;
    @apply bg-green-50;
    @apply scroll-smooth;
}

html, body, #__next {
    @apply h-full;
}
